import './SideBar.css';
//import Sidebar from './components/Sidebar';
import Sidebar from './pages/components/Sidebar';
import { BrowserRouter as Router,   Route, Routes } from 'react-router-dom';

import Overview from './pages/Overview';
import { Reports, ReportsOne, ReportsTwo, ReportsThree } from './pages/Reports';
import Team from './pages/Team';

function SideBar() {
  return (
    <>
      <Sidebar />
      <Routes> 
        <Route path='/overview' exact component={Overview} />
        <Route path='/reports' exact component={Reports} />
        <Route path='/reports/reports1' exact component={ReportsOne} />
        <Route path='/reports/reports2' exact component={ReportsTwo} />
        <Route path='/reports/reports3' exact component={ReportsThree} />
        <Route path='/team' exact component={Team} />
      </Routes> 
    </>
  );
}

export default SideBar;
import React from 'react'


// D|/Digital/NodeCotizacion/index.js
// action="file:///C|/Archivos%20de%20programa/Microsoft%20Office/OFFICE11/WINWORD.EXE">

// action="file:///D|/Digital/NodeCotizacion/index.js"

export default function  ExecFile()

 {
    const  submit = (e) =>
    {
        e.preventDefault() ;


        //window.open("file:///D|/Digital/NodeCotizacion/index.js", null);


        if ( window.ActiveXObject )
        {
            var WshShell = new window.ActiveXObject("WScript.Shell");
            WshShell.Run("d:/Digital/NodeCotizacion/index.js", 1, false);

        }
        else{
            console.log( "No se tiene ActiveX") ;
        }




    } 
  return (
    <div>
        <form id= "form1" onSubmit={submit}
            name=  "form1"
            method="post"
            >
            <label> Para ejecutar un archivo del ordenador 
                (en este ejemplo se lanza el Robot)
                 pulse sobre el botón: 
             <input type="submit"
                    name="Submit"
                    value="Abrir Robot" />
             </label>
        </form>

    </div>
  )
}

import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';

import getGlobal from '../../setglobal';

const BesView = ({iniDate}) => {
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [first,setFirst] = useState( 0 ) ;

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(
        'bescustomer', getGlobal() 
        // process.env.NODE_ENV === 'production'
        //   ? 'https://www.material-react-table.com'
        //   : 'http://localhost:3000',
      );
      url.searchParams.set(
        'start',
        `${ ( pagination.pageIndex * pagination.pageSize ) + first }`,
//        `${ ( pagination.pageIndex * pagination.pageSize ) + first }`,
      );
      url.searchParams.set('size', `${pagination.pageSize}`);
      url.searchParams.set('iniDate', iniDate );
      url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      url.searchParams.set('globalFilter', globalFilter ?? '');
      url.searchParams.set('sorting', JSON.stringify(sorting ?? []));


      console.log( url.href ) ;

      try {
        const response = await fetch(url.href);
        const json = await response.json();

        console.log( JSON.stringify( json ) )

        setData(json.data);
        if ( json.data.length > 0 )
        {
          console.log( "setFirst = " + json.data[0].idbescustomer + " pageIndex = " + pagination.pageIndex + " pageSize = " + pagination.pageSize )
          if ( first === 0 )
          {
            setFirst( json.data[0].idbescustomer) ;
          }
          
        }
        setRowCount(json.totalRowCount);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'idbescustomer',
        header: 'Id',
      },
      {
        accessorKey: 'cuotaRenovacionAnticipada',
        header: 'Cuota Renov Ant',
      },      
      {
        accessorKey: 'equipoContratado',
        header: 'Equipo Contratado',
      },
      {
        accessorKey: 'marcaEquipo',
        header: 'Marca Equipo',
      },

      {
        accessorKey: 'modeloEquipo',
        header: 'Modelo Equipo',
      },

      {
        accessorKey: 'idOfertaPrimariaActual',
        header: 'Oferta Primaria Actual',
      },

      {
        accessorKey: 'planActual',
        header: 'PLan Actual',
      },

      {
        accessorKey: 'periodoRestantePlan',
        header: 'Periodo Restante',
      },
      {
        accessorKey: 'fechaTermino',
        header: 'Fecha Termino',
      },

      {
        accessorKey: 'suscripcion',
        header: 'Telefono',
      },

      {
        accessorKey: 'totalSaldoActual',
        header: 'Tot Saldo Actual',
      },

      {
        accessorKey: 'saldoPendiente',
        header: 'Saldo Pendiente',
      },

      {
        accessorKey: 'regionActual',
        header: 'Region Actual',
      },

      {
        accessorKey: 'nombre',
        header: 'Nombre',
      },

      {
        accessorKey: 'primerApellido',
        header: 'Appellido Pat.',
      },

      {
        accessorKey: 'segundoApellido',
        header: 'Appellido Mat.',
      },

      {
        accessorKey: 'email',
        header: 'Correo',
      },

      {
        accessorKey: 'metodoPago',
        header: 'Metodo Pago',
      },

      {
        accessorKey: 'nIsRenovacion',
        header: 'Is Renovacion',
      },

      {
        accessorKey: 'dTimeStamp',
        header: 'Fecha Registro',
      },


      //column definitions...
    ],
    [],
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableRowSelection
      getRowId={(row) => row.phoneNumber}
      initialState={{ showColumnFilters: true }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={rowCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
    />
  );
};

export default BesView;
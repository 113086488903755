import React from 'react'

export default function ExcelLoadOk() {
    function cargado()
    {
        

    }
  return (
    <div className='row'>
        <div className='col-4'></div>
        <div className='col-4'>
            <div>
                <a href={cargado}>La carga de datos ha terminado Satistactoriamente!!!</a>
            </div>
        </div>

    </div>
  )
}

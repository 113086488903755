import React from 'react'
import SideBar from '../SideBar/SideBar'

import Logo from "../../images/digital03.jpg"
import Telcel from "../../images/digital02.jpg"


// #343131

export default function Header() {
  return (
    <div>

        <header  style={{background:'#0a0a0a' , height: '60px'}}>

            <div className='row pt-2'>
                <div className='col-3'>

                    <SideBar></SideBar>

                </div>

                <div className=' col-2' style={{color:'white'}}>
              
                    {/* <img height={40} src='digital02' alt=''></img>  */}
                    
                    <img className='pt-0' src={Logo} height='45px'  alt='' />
                </div>

                <div className='col-3' style={{color:'white' , fontSize:35}}>

                    <p>Cotizaciones</p>

                </div>


                <div className='col-3'>
                    <img className='pt-0' src={Telcel} height='45px'  alt='' />
                </div>

            </div>

        

        </header>
    </div>
  )
}

//import logo from './logo.svg';
import './App.css';




import { BrowserRouter, Route, Routes} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './Rutas/Header/Header';
import ExcelLoad from './Uploads/ExcelLoad';
import ExecFile from './Rutas/Execs/ExecFile';
import BesViewPrev from './Rutas/BesCustomer/BesViewPrev';

function App() {
  return (

  
    <BrowserRouter>
      <Header></Header>
      <Routes>
        {/* <Route path="/" Link="/Header/Header" element={<Header />} /> */}
        <Route path="/uploads" Link="./Uploads/ExcelLoad" element={<ExcelLoad />} />
        <Route path="/execfile" Link="./Rutas/Execs/ExecFile" element={<ExecFile />} />
        <Route path="/besview" Link="./Rutas/BesCustomer/BesView" element={<BesViewPrev></BesViewPrev>} />
      </Routes>
    </BrowserRouter>
    

    // <div className="App">
    //   <Header></Header>
    //   <ExcelLoad></ExcelLoad>
    // </div>
  );
}

export default App;
